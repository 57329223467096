
/*
 * Filename: site-header.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This module will handle the logic for the sticky site header.
 *
 */


export default function SiteHeader() {

  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false,
    delta: 95
  };

  // DOM NODES
  const elem = document.getElementsByClassName('site-header')[0];


  // DATA VARIABLES - private variables
  const headerHeight = elem.offsetHeight;

  let timeout = null;   
  let didScroll = false;   
  let lastScrollTop = 0;
  

  init();

  // initialize this module
  function init() {
    debug('insider SiteHeader init()');

    window.addEventListener('scroll', handleScroll);

  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  // PRIVATE FUNCTIONS
  //===============================================
  
  // lock header position
  function lockHeader() {
    elem.classList.add('fixed');
  }

  // unlock header
  function unlockHeader() {
    elem.classList.remove('fixed');
  }
  
  // slide header in and out
  function headerHideShow( scrollTop ) {

    debug('');
    debug('SiteHeader Readout');
    debug('-----------------------------------');
    debug('scrollTop: ' + scrollTop);
    debug('lastScrollTop: ' + lastScrollTop);
    debug('headerHeight: ' + headerHeight);
    debug('defaults.delta: ' + defaults.delta);

    // ensure scroll has past the threshold
    if ( Math.abs(lastScrollTop - scrollTop) <= defaults.delta)
      return

    if (scrollTop > lastScrollTop && scrollTop > headerHeight) {
      // scrolling down use case
      elem.classList.add('header-up');
      elem.classList.remove('header-down');

    } else {
      // scrolling up use case
      if (scrollTop + window.innerHeight < document.body.clientHeight) {
        elem.classList.remove('header-up');
        elem.classList.add('header-down');
      }
    }

    lastScrollTop = scrollTop;
  }

  // PUBLIC FUNCTIONS
  //===============================================
  

  // debounced actions
  function debouncedActions() {
    debug('debounced actions');

    let currentScrollTop = window.pageYOffset;

    // fixed header conditional
    if ( currentScrollTop > 0 ) {
      lockHeader();
    } else {
      unlockHeader();
    }

    headerHideShow(currentScrollTop);
  }

   // Scrolling event handler.
  function handleScroll() {
    debug('not debounced actions');

    // cancel existing timer
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame( debouncedActions );
  }
  
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.debouncedActions = debouncedActions;
  api.handleScroll = handleScroll;
  return api;

}
