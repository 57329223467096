
/*
 * Filename: vidyard-lightbox.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This module will power vidyard lightboxes from specialized <a> tags with a 
 * class of 'vidyard-video'.

    SAMPLE MARKUP:
    <a data-uuid="RmxMxmqQrMhU9E7CVkWw7q" class="tertiary-btn media-btn vidyard-video" href="javascript:;">Watch Video</a>
 *
 */


export default function VidyardLightbox() {

  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false
  };

  // DOM NODES
  const lightBoxNode = document.getElementById('lightbox-container');
  const triggers = document.getElementsByClassName('vidyard-video');

  // DATA VARIABLES - private variables
  let initialized = false;
  let templateStr = '';
  let uuid = null;

  let videoContainer = null;     // dynamic vidyard video container
  let videoTrigger = null;       // dyanmic vidyard lightbox trigger
  let interval = null;           // stores the interval 


  // TEMPLATE BLOCKS
  // Vidyard embed template
  let embedTmpl = '<img ' +
                    'style="width: 100%; margin: auto; display: none; height: 0px;" ' +
                    'class="vidyard-player-embed" ' +
                    'src="https://play.vidyard.com/{{ uuid }}.jpg" ' +
                    'data-uuid="{{ uuid }}" ' +
                    'data-v="4" ' +
                    'data-type="lightbox" ' +
                  '/> ';

  init();


  // initilize the module
  function init() {
    debug('insider VidyardLightbox init()');

    if (triggers.length != 0) {
      for (let x = 0; x < triggers.length; x++) {
        initTemplate( triggers[x] );
      }

      // delay 1sec. to allow vidyard libraries to
      // create dyanamic video containers
      interval = setInterval( configHiddenVidyard, 500 );
    }
  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  
  // PUBLIC FUNCTIONS
  //===============================================
  
  // This function will dynamically embed vidyard video embeds
  // based on the uuid attribute
  function initTemplate(elem) {
    uuid = elem.dataset.uuid;
    templateStr = embedTmpl.replace( /{{ uuid }}/g, uuid);
    lightBoxNode.insertAdjacentHTML('beforeend', templateStr);

    debug('uuid: ' + uuid);
    debug('templateStr: ' + templateStr);
  }
  
  // This function will be delayed in execution to allow
  // the vidyard libary code to create its dynmaic containers.  After which
  // we will bind an event handler to our custom container to trigger the
  // vidyard lightbox.
  function configHiddenVidyard() {
    let videoContainers, vidyardTrigger;

    // rerun actions until dynamic shell has loaded.
    if ( triggers.length && !initialized ) {

      // Dynamic container
      videoContainers = lightBoxNode.querySelectorAll('.vidyard-player-container');

      if ( videoContainers.length > 0 ) { 
        for (let x = 0; x < videoContainers.length; x++) {
          videoContainers[x].classList.add('hide');

          // Lightbox Trigger node
          vidyardTrigger = videoContainers[x].querySelector('.vidyard-lightbox-centering');

          // Bind click event to our vidyard trigger
          if (triggers[x] != null) {
            triggers[x].addEventListener('click', function(event) {
              event.preventDefault();
              vidyardTrigger.click();
              debug( 'click occurred' );
            });
          }
        }

        initialized = true;
      }

      debug("configHiddenVidyard ran.");
      debug('initialized: ' + initialized );
      debug('videoContainers: ' + videoContainers );
      debug('vidyardTrigger: ' + vidyardTrigger );

    } else {
      // clear interval once shell has been updated.
      debug("clear configHiddenVidyard interval.");
      clearInterval( interval );
    }


  }
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.initTemplate = initTemplate;
  api.configHiddenVidyard = configHiddenVidyard;
  return api;

}
