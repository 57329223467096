/*
 * Filename: homepage.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This will house the logic for the homepage.
 *
 */

// Third party libraries
import lottie from 'lottie-web';

// Page Modules
import ComparisonSlider from '../modules/comparison-slider';

// OWNW JSON imports
import ivrData from './ivr.json'; 
import roboticData from './robotic.json';
import frustrationData from './frustration.json'; 
import ccData from './cc.json'; 
import churnData from './churn.json'; 

const options = {
  activePanelThreshold: .4,              // 0 to 1 (1 = 100% in viewport)
  debugMode: false
}

// DOM NODES
const comparisonSliderNodes = document.getElementsByClassName('comparison-slider');

const ivrNode = document.getElementById('ivr-b');
const roboticNode = document.getElementById('robotic');
const frustrationNode = document.getElementById('frustration');
const ccNode = document.getElementById('cc-b');
const churnNode = document.getElementById('churn');

// Private Variables
const animationNodes = [ivrNode, roboticNode, frustrationNode, ccNode, churnNode];
let animationHandles = [];

let observer;
let prevRatio = 0.0;


init();
 

function init() {

  // setup comparison sliders
  for (let x = 0; x < comparisonSliderNodes.length; x++) {
    ComparisonSlider( comparisonSliderNodes[x] );
    debug('init comparisonSliders');
  }

  let ivrB = lottie.loadAnimation({
    container: ivrNode,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: ivrData
  });

  let robotic = lottie.loadAnimation({
    container: roboticNode,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: roboticData
  });

  let frustration = lottie.loadAnimation({
    container: frustrationNode,
    renderer: 'svg',
    loop: false, 
    autoplay: false,
    animationData: frustrationData
  });

  let ccB = lottie.loadAnimation({
    container: ccNode,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: ccData 
  }); 

  let churn = lottie.loadAnimation({
    container: churnNode,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: churnData
  });

  // store animation handles
  animationHandles.push(ivrB);
  animationHandles.push(robotic);
  animationHandles.push(frustration);
  animationHandles.push(ccB);
  animationHandles.push(churn);

  initIntersectionObservers();

}

// This function will setup the intersection observers
function initIntersectionObservers() {

  let observerOpts = {
    root: null,
    rootMargin: "0px",
    threshold: options.activePanelThreshold
  };

  observer = new IntersectionObserver(handleIntersect, observerOpts);

  for (let x = 0; x < animationNodes.length; x++) {  
    observer.observe( animationNodes[x] );
  }

}


// This function will handle the intersection.  
function handleIntersect(entries) {
  debug('inside handleIntersect()');

  entries.forEach((entry) => {
    let animationIndex = 0;
    let element = entry.target;
    let panelParent = element.closest('.active-panel');

    if (entry.isIntersecting) {
      if (entry.intersectionRatio >= options.activePanelThreshold) {
        debug("Animation entered the screen");
        
        animationIndex = getAnimationIndex(element);
        panelParent.classList.add('show');

        if (animationIndex != -1){
          debug("play animation from panel: " + animationIndex);
          animationHandles[animationIndex].play();
        
          debug("removing observer for: " + element);
          observer.unobserve( element );
        }
      }
    }
  });
}


// HELPER FUNCTIONS
//===============================================


// This function will take a target node and return what
// index value it is within the animationNodes[]
function getAnimationIndex(target) {
  let index = -1;

  for (let x = 0; x < animationNodes.length; x++) {
    if (target == animationNodes[x]) {
      index = x;
    }
  }

  return index;
}


// Basic debug function
function debug(msg) { 
  if (options.debugMode) {
    console.log(msg);
  }
}

