/*
 * Filename: core.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This chunk will be used on global features needed on every page.
 * It will be bundled with every new page template entry point.
 *
 */

import PromoBanner from './modules/promo-banner';
import SiteHeader from './modules/site-header';
import SmoothScroll from './modules/smooth-scroll'; 
import SvgSpriteLoader from './modules/svg-sprite-loader';
import VidyardLightbox from './modules/vidyard-lightbox';


const options = {             
  debugMode: false
}


init();
 

function init() {
  PromoBanner();
  SiteHeader();
  SmoothScroll();
  SvgSpriteLoader();
  VidyardLightbox();

}


// Basic debug function
function debug(msg) { 
  if (options.debugMode) {
    console.log(msg);
  }
}

