
/*
 * Filename: promo-banner.js
 * By: J. Nguyen
 * Date: 06/07/21
 *
 * Description: 
 * This module will control the promo banner.
 *
 */


export default function PromoBanner() {

  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false
  };

  // DOM NODES
  const body = document.getElementsByTagName('body')[0];
  const promoBannerNode = document.getElementById('promo-banner');

  const closeBtn = promoBannerNode.getElementsByClassName('close-btn')[0];
  const bannerText = promoBannerNode.getElementsByClassName('textwidget')[0];        // Built in widget mark up for content


  // DATA VARIABLES - private variables
  let variable = null;

  init();

  // initilize the module
  function init() {
    debug('insider PromoBanner init()');

    // detect if promo exists
    if ( checkPromo() && hasBannerContent() ) {
      openBanner();
      closeBtn.addEventListener("click", setPromoCookieAndClose);
    }
  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  // PRIVATE FUNCTIONS
  //===============================================
  
  // This function will return the current domain
  // removed of any subdomains or null if local env is detected.
  function getDomain() {

    var domain = window.location + '';
    var splitArr = domain.split('.');
    var arrLen = splitArr.length;

    //if there is a subdomain 
    if (arrLen > 2) {
      domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1].split('/')[0];
      domain = '.' + domain;
    }

    // meaning we're on local environments
    if (arrLen == 1) {
      return null
    }

    return domain;
  }

  
  // PUBLIC FUNCTIONS
  //===============================================
  
  // This function will open the promo banner
  function openBanner() {
    body.classList.add('promo-banner-open');
  }

  // This function will close the promo banner
  function closeBanner() {
    body.classList.remove('promo-banner-open');
  }

  // This function will check if the user has the promo
  // cookie already set on their system.  It will return true if the
  // cookie is not set, otherwise it will return false regardless of the
  // cookie value. This is used to determine whether to show the banner or not.
  function checkPromo() {
    var interactionsPromoVal = document.cookie.replace(/(?:(?:^|.*;\s*)promoVerified\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    debug("promo value is: " + interactionsPromoVal );
    switch(interactionsPromoVal) {
      case '1':
      case '0':
        return false;

      default:
        return true;
    }
  }

  function hasBannerContent() {
    return bannerText && bannerText.innerHTML && bannerText.innerHTML.length;
  }

  // This function will close the promo content
  // Promo will re-appear after 24 hours
  function setPromoCookieAndClose() {
    debug('promo set to: TRUE, ' + getDomain() );

    var domain = getDomain();

    // expires in 24 hrs
    var expires = new Date( new Date().setHours( new Date().getHours() + 24));

    if (domain) {
      document.cookie = "promoVerified=1; domain="+ getDomain() +"; path=/; expires="+ expires.toUTCString();
    } else {
      document.cookie = "promoVerified=1; path=/; expires="+ expires.toUTCString();
    }

    closeBanner();
  }
  
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.openBanner = openBanner;
  api.closeBanner = closeBanner;
  api.checkPromo = checkPromo;
  api.setPromoCookieAndClose = setPromoCookieAndClose;
  return api;

}
