
/*
 * Filename: comparison-slider.js
 * By: J. Nguyen
 * Date: 06/07/21
 *
 * Description: 
 * This module will create ComparisonSlider modules.
 *
 */


export default function ComparisonSlider(element) {

  const elem = element;                       // remember the DOM element that this object is bound to
  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false,
    debounceTiming: 250,
    mode: 'image',                            // (image | animation) determines whether slider content mode to enable
    offsetLimit: 25,                          // offset value of the slider's farest edges
  };


  // DOM Nodes
  const handle = elem.getElementsByClassName('handle')[0]; 
  const resizeContainer = elem.getElementsByClassName('resize')[0]; 


  // DATA VARIABLES - private variables
  // -----------------------------------------
  let resizeContents = null;        // based on the mode, this will dynamically get set by modeSetup()

  let mode = ( elem.dataset.mode ? elem.dataset.mode : defaults.mode );
  let offsetLimit = ( elem.dataset.offsetLimit ? elem.dataset.offsetLimit : defaults.offsetLimit );  
  let resizeTimer = null;

  init();

  // initialize this plugin
  function init() {

    debug('inside ComparisonSlider init()');
    modeSetup();
    setContentWidth();

    // Resize handler
    window.addEventListener('resize', resizeHandler);

    // Drag handler
    handle.addEventListener('mousedown', dragHandler, {passive: true});
    handle.addEventListener('touchstart', dragHandler, {passive: true});

  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  // PRIVATE FUNCTIONS
  //===============================================
  
  // determine the default mode setting and set resizeContainer accordingly.
  function modeSetup() {
    if (mode == 'animation') {
      resizeContents = elem.querySelectorAll('.resize > div')[0];
      debug('mode is animation: ' + resizeContents);

    } else if (mode == 'image') {
      resizeContents = elem.querySelectorAll('.resize img')[0];
      debug('mode is image: ' + resizeContents);

    } else {
      debug('Error: Invalid mode type.');
    }
  }

  // resize handler - debounced by the debounceTiming value
  function resizeHandler(event) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      setContentWidth();       
    }, defaults.debounceTiming);
  }

  // clear the draggable/resizable state classes
  function clearMovement() {
    handle.classList.remove('draggable');
    resizeContainer.classList.remove('resizable');
  }
  
  // PUBLIC FUNCTIONS
  //===============================================
  
  // update the resize container's contents dynamically
  function setContentWidth() {
    let width = elem.offsetWidth + 'px';
    resizeContents.style.width = width;
    debug('width is: ' + width);
  }

  // event handler for dragging the handle
  function dragHandler(event) {

    var containerOffset, containerWidth, dragWidth, moveX, leftValue, posX, startX, widthValue;

    handle.classList.add('draggable');
    resizeContainer.classList.add('resizable');

    // Check if it's a mouse or touch event and pass along the correct value
    startX = (event.pageX) ? event.pageX : event.originalEvent.touches[0].pageX;
    
    // Get the initial position
    dragWidth = handle.offsetWidth,
    posX = handle.offsetLeft + dragWidth - startX,
    containerOffset = elem.offsetLeft,
    containerWidth = elem.offsetWidth;
 
    // Set limits
    var minLeft = containerOffset + offsetLimit;
    var maxLeft = containerOffset + containerWidth - dragWidth - offsetLimit;

    var checkEvent = function(e) {
      // Check if it's a mouse or touch event and pass along the correct value
      moveX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;
      
      leftValue = moveX + posX - dragWidth;
      
      // Prevent going off limits
      if ( leftValue < minLeft) {
        leftValue = minLeft;
      } else if (leftValue > maxLeft) {
        leftValue = maxLeft;
      }
      
      // Translate the handle's left value to masked divs width.
      widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';
      
      // Set the new values for the slider and the handle. 
      // Bind mouseup events to stop dragging.
      var draggable = elem.getElementsByClassName('draggable')[0];
      var resizable = elem.getElementsByClassName('resizable')[0];

      if (draggable && resizable) {
        draggable.style.left = widthValue;
        resizable.style.width = widthValue;

        draggable.addEventListener('mouseup', clearMovement);
        draggable.addEventListener('touchend', clearMovement);
        draggable.addEventListener('touchcancel', clearMovement);
      }

      e.preventDefault();
    };

    // Calculate the dragging distance on mousemove.
    elem.addEventListener('mousemove', checkEvent);
    elem.addEventListener('touchmove', checkEvent);

    elem.addEventListener('mouseup', clearMovement);
    elem.addEventListener('touchend', clearMovement);
    elem.addEventListener('touchcancel', clearMovement);
  }
  
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.setContentWidth = setContentWidth;
  api.dragHandler = dragHandler;
  return api;

}


