
/*
 * Filename: svg-sprite-loader.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This module will load 
 *
 */


export default function SvgSpriteLoader() {

  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false,
    svgSpritePath: interactions.svgSpritePath
  };


  // DATA VARIABLES - private variables
  let variable = null;

  init();

  // initialize this plugin
  function init() {
    loadSvgSprite();
  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  
  // PUBLIC FUNCTIONS
  //===============================================
  
  function loadSvgSprite() {
 
    if (defaults.svgSpritePath != '') {
      
      

      var httpRequest = new XMLHttpRequest();
      httpRequest.onreadystatechange = function (event) {
       
        if (event.target.responseXML) {
          var div;

          div = document.createElement("div");
          div.className = 'svg-sprite hide';
          div.innerHTML = new XMLSerializer().serializeToString( event.target.responseXML.documentElement );

          document.body.insertBefore(div, document.body.childNodes[0]);
        }
      }

      httpRequest.open('GET', defaults.svgSpritePath);
      httpRequest.send();

    }

  }
  
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.loadSvgSprite = loadSvgSprite;
  return api;

}
