
/*
 * Filename: smooth-scroll.js
 * By: J. Nguyen
 * Date: 6/7/21
 *
 * Description: 
 * This module will handle smooth scrolling when used with a hash link and 
 * named anchor on the page.
 *
 */


export default function SmoothScroll() {

  let api = {};                               // Object to contain the publicly accessible functions

  // default options
  const defaults = {
    debugMode: false
  };

  // DATA VARIABLES - private variables
  const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');

  init();

  // initialize this plugin
  function init() {

    for (const link of links) {
      link.addEventListener("click", clickHandler);
    }

  }


  // HELPER FUNCTIONS
  //===============================================
  
  // Basic debug function
  function debug(msg) { 
    if (defaults.debugMode) {
      console.log(msg);
    }
  }

  
  // PUBLIC FUNCTIONS
  //===============================================
  
  // Click handler function
  function clickHandler(event) {
    event.preventDefault();
    const href = this.getAttribute("href");
    const ignore = this.dataset.ignoreScroll;

    if (!ignore) {
      document.querySelector(href).scrollIntoView({ 
        behavior: 'smooth' 
      });
    } else {
      window.location.href = href;
    }

    debug('SmoothScroll to: ' + href );
    debug('Ignore flag is: ' + ignore);
  }
  
  
  // EXPOSE PUBLIC FUNCTIONS VIA THE API
  // Map all the functions we want to expose for public access
  //===============================================================
  api.clickHandler = clickHandler;
  return api;

}
